<template>
    <div class="page-success">
        <div class="columns is-multiline">
            <div class="column is-12">
                <h1 class="title">Thank you</h1>

                <p>Your order will be processed within 2 Weeks</p>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {

    name: 'Success',
    async mounted() {
        document.title = 'Success | Home'
        const stripe = window.Stripe('pk_test_51LxyjzIWUBovXIZTL40jdExPEwLxiyQIWUvYaWKMoTHSNDAS8027JgRaVNtmBkFC0o69704rqcVa6BlAk02JuwLV00md9xHikS')
        const clientSecret = new URLSearchParams(
            window.location.search
        ).get(
            'payment_intent_client_secret'
        )
        const {
            paymentIntent, error
        } = await stripe.retrievePaymentIntent(
            clientSecret
        )
        if (error) {
            console.log(error)
            this.message = 'An error has ocurred'
        }
        if (paymentIntent.status === 'succeeded') {
            this.message = paymentIntent.status
            const payment_id = paymentIntent.id
            this.$store.dispatch('checkout', { payment_id })
                .catch((_err) => {
                    this.loading = false
                    const show = true
                    const color = 'red darken-3'
                    const text = 'An error has ocurred'
                    this.$store.commit('cartSnack',
                        {
                            show, color, text
                        })
                })
        } else {
            this.message = paymentIntent.status
        }
    },
    mutations: {
        CHECKOUT_SUCCESS(state) {
            state.stars = []
            state.cart = []
        },
    }
}

</script>